
import {PropType, defineComponent} from 'vue';
import ProfilePicture from '@/components/users/ProfilePicture.vue';
import Team from '@/models/Team';

export default defineComponent({
    components: {
        ProfilePicture,
    },
    props: {
        team: {
            type: Object as PropType<Team>,
            required: true,
        },
    },
    computed: {
        hasPitcher(): boolean {
            return !!this.team.pitcher?.id;
        },
    },
    methods: {
        goToTeamUsers() {
            this.$router.push(this.team.getLocation(
                'edit',
                {
                    view: 'members',
                },
            ));
        },
    },
});
