import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body py-10 d-flex flex-column justify-content-center align-items-center border-bottom" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "mt-6" }
const _hoisted_5 = {
  key: 0,
  class: "col-auto"
}
const _hoisted_6 = {
  key: 1,
  class: "col text-truncate"
}
const _hoisted_7 = { class: "col-auto text-end" }
const _hoisted_8 = { class: "ms-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfilePicture = _resolveComponent("ProfilePicture")!
  const _component_Fa = _resolveComponent("Fa")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "square-xxl rounded-3",
        src: _ctx.team.logo?.thumbSm
      }, null, 8, _hoisted_3),
      _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.team.name), 1)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["card-body row align-items-center py-4", [_ctx.hasPitcher ? 'justify-content-between' : 'justify-content-end']])
    }, [
      (_ctx.hasPitcher)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_ProfilePicture, {
              class: "square-sm rounded-circle",
              user: _ctx.team.pitcher
            }, null, 8, ["user"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.hasPitcher)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.team.pitcher.fullName), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("button", {
          class: "btn btn-light text-muted px-4 py-2 border rounded-pill",
          "data-test-id": "btn-team-users",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.goToTeamUsers && _ctx.goToTeamUsers(...args)), ["stop"]))
        }, [
          _createVNode(_component_Fa, { icon: "user-friends" }),
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.team.usersCount), 1)
        ])
      ])
    ], 2)
  ]))
}