
import {PropType, defineComponent} from 'vue';
import CreateModal from '@/components/teams/teams/CreateModal.vue';
import {Teams} from '@/models/Team';

export default defineComponent({
    components: {
        CreateModal,
    },
    props: {
        teams: {
            type: Object as PropType<Teams>,
            default: null,
        },
    },
});
