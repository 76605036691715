import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ms-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _component_CreateModal = _resolveComponent("CreateModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", _mergeProps(_ctx.$attrs, {
      class: "btn btn-primary d-flex align-items-center",
      "data-bs-target": "#team-create",
      "data-bs-toggle": "modal"
    }), [
      _createVNode(_component_Fa, {
        icon: "plus",
        size: "sm"
      }),
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('teams.actions.addTeam')), 1)
    ], 16),
    _createVNode(_component_CreateModal, { teams: _ctx.teams }, null, 8, ["teams"])
  ], 64))
}