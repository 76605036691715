import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BtnResource = _resolveComponent("BtnResource")!
  const _component_TeamForm = _resolveComponent("TeamForm")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    name: _ctx.name,
    "no-footer": "",
    size: "lg",
    title: _ctx.$t('teams.create.title')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_TeamForm, {
        "add-leaders": "",
        "add-members": _ctx.addMembers,
        class: "text-start",
        "edit-team": _ctx.newTeam,
        onTeamSaved: _ctx.hideCreateModal
      }, {
        "form-submit": _withCtx(({team}) => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("button", {
              class: "btn btn-outline-light",
              type: "button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addMembers = !_ctx.addMembers))
            }, _toDisplayString(_ctx.$t(`teams.actions.${_ctx.addMembers ? 'cancelAddMembers' : 'addMembers'}`)), 1),
            _createVNode(_component_BtnResource, {
              class: "ms-4",
              resource: team,
              type: "submit"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('teams.actions.sendInvite')), 1)
              ]),
              _: 2
            }, 1032, ["resource"])
          ])
        ]),
        _: 1
      }, 8, ["add-members", "edit-team", "onTeamSaved"])
    ]),
    _: 1
  }, 8, ["name", "title"]))
}