
import {PropType, defineComponent, ref} from 'vue';
import Team, {Teams} from '@/models/Team';
import BtnResource from '@/components/common/model-collection/BtnResource.vue';
import Modal from '@/components/common/Modal.vue';
import TeamForm from '@/components/teams/teams/Form.vue';
import {hideModal} from '@/library/helpers';
import onboardingStore from '@/store/onboarding';
import userTeamsStore from '@/store/userTeams';

export default defineComponent({
    components: {
        BtnResource,
        Modal,
        TeamForm,
    },
    props: {
        teams: {
            type: Object as PropType<Teams>,
            default: null,
        },
    },
    setup(props) {
        const {teamProgram} = userTeamsStore;

        return {
            teamProgram,
            addMembers: ref<boolean>(false),
            name: 'team-create',
            newTeam: ref<Team>(new Team({}, props.teams)),
        };
    },
    methods: {
        hideCreateModal(team: Team) {
            onboardingStore.completeStep('inviteTeam');

            hideModal(this.name);

            this.$router.push(team.getLocation());
        },
    },
});
