
import Team, {Teams} from '@/models/Team';
import {defineComponent, ref} from 'vue';
import {fetchCollectionUntilScrollable, firstScrollingParent} from '@/library/helpers';
import BtnCreateModal from '@/components/teams/teams/BtnCreateModal.vue';
import {Portal} from 'portal-vue';
import TeamCard from '@/components/teams/Card.vue';
import teamStore from '@/store/userTeams';

export default defineComponent({
    components: {
        BtnCreateModal,
        Portal,
        TeamCard,
    },
    setup() {
        const {teamProgram} = teamStore;

        return {
            teamProgram,
            teams: ref<Teams>(new Teams([], {
                include: [
                    'pitcher',
                    'usersCount',
                ],
            })),
        };
    },
    computed: {
        noTeams(): boolean {
            return this.teams.isEmpty()
                && !this.teams.loading;
        },
    },
    watch: {
        'teamProgram.id': function handler() {
            this.fetchTeams();
        },
    },
    mounted() {
        if (this.teamProgram.id) {
            this.fetchTeams();
        }
    },
    methods: {
        fetchTeams() {
            this.teams.clear();

            fetchCollectionUntilScrollable(
                this.teams,
                firstScrollingParent(this.$el),
                {
                    url: `/teams/${this.teamProgram.id}/teams`,
                },
            );
        },
        goToTeam(team: Team) {
            this.$router.push(team.getLocation());
        },
    },
});
